/* @jsx jsx */
import React from 'react'
import { jsx, Text, Flex } from 'theme-ui'
import { merchantPriceFormatter } from '~/utils/merchant-feed/price-formatter'
import { graphql, useStaticQuery } from 'gatsby'

const LineItemPrice = props => {
  const {
    variant,
    total,
    adjustments,
    displayAmount,
    quantity,
    orderConfirmed,
  } = props

  //   We need this query since chord doesn't pass the price & regular price values via the oms
  const data = useStaticQuery(graphql`
    query allVariants {
      allContentfulVariant {
        edges {
          node {
            sku
            price
            regularPrice
          }
        }
      }
    }
  `)
  const currCMSVariant = data?.allContentfulVariant?.edges?.find(
    e => e.node.sku === variant.sku,
  )
  let salePriceActive =
    currCMSVariant &&
    currCMSVariant?.node?.price !== currCMSVariant?.node?.regularPrice

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-end',
        mt: orderConfirmed ? 0 : ['-10px', '-20px', '-20px'],
        color: 'sunsetOrange',
        width: ['130px'],
        fontSize: ['0.8rem', '1rem', '1rem'],
      }}
    >
      {salePriceActive && (
        <Flex
          sx={{
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Text>Reg. Price:</Text>
          <Text
            sx={{
              textDecoration: 'line-through',
            }}
          >
            ${(currCMSVariant?.node?.regularPrice * quantity).toFixed(2)}
          </Text>
        </Flex>
      )}
      <Flex
        sx={{
          justifyContent:
            adjustments.length > 1 || salePriceActive
              ? 'space-between'
              : 'start',

          width: '100%',
          color:
            adjustments.length || !salePriceActive ? 'sunsetOrange' : 'blue',
        }}
      >
        {salePriceActive && <Text>Sale Price:</Text>}
        {adjustments.length > 1 && !salePriceActive && <Text>Reg. Price:</Text>}

        <Text
          sx={{
            textDecoration: adjustments.length > 1 ? 'line-through' : 'none',
            alignSelf: adjustments.length > 1 ? 'end' : 'start',
          }}
        >
          {displayAmount}
        </Text>
      </Flex>
      {adjustments.length > 1 && (
        <Flex
          sx={{
            justifyContent: 'space-between',
            width: '100%',
            color: 'blue',
          }}
        >
          <Text>Promo:</Text>
          <Text>${merchantPriceFormatter.format(total)}</Text>
        </Flex>
      )}
    </Flex>
  )
}

export default LineItemPrice
