import { graphql, useStaticQuery } from 'gatsby'

const useDonationImgQuery = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allContentfulVariant(filter: { sku: { eq: "donation1" } }) {
          edges {
            node {
              sku
              product {
                mainImage {
                  fluid(maxWidth: 500, quality: 80) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return data
}

export default useDonationImgQuery
