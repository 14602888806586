/** @jsx jsx */
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Flex, jsx, Select, Button, Grid } from 'theme-ui'
import Spinner from '../Spinner'

const QuantitySelector = ({
  handleChange,
  options = [],
  max,
  min,
  alt,
  ...props
}) => {
  const { register, getValues, setValue } = useForm()
  const handleStepValue = stepAmount => {
    let currentVal = +getValues('quantity')
    let targetVal = currentVal + stepAmount
    if (targetVal < min || targetVal > max) {
      return
    } else {
      setValue('quantity', +getValues('quantity') + stepAmount)
      onChange()
    }
  }
  const onChange = () => {
    const quantity = getValues('quantity')
    if (handleChange) handleChange(quantity)
  }
  let localButtonStyle = {
    background: 'white',
    border: 2,
    borderColor: 'override.detailMain',
    borderStyle: 'solid',
    height: '100%',
    width: 'auto',
    lineHeight: '0',
    padding: 0,
    paddingLeft: '10px',
    paddingRight: '8px',
    '& svg': {
      '& g path': {
        fill: 'override.detailMain',
      },
      width: 22,
      height: 22,
    },
  }

  const altButtonStyle = {
    border: 0,
    background: 'none',
    '& svg': {
      width: 16,
      height: 16,
    },
  }

  localButtonStyle = alt
    ? { ...localButtonStyle, ...altButtonStyle }
    : localButtonStyle

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '80px',
        marginTop: '.75em',
      }}
    >
      {props.isLoading && <Spinner color="inherit" />}
      {!props.isLoading && (
        <Grid columns={'auto min-content auto'} gap={0} sx={{ height: 45 }}>
          <Button
            sx={{
              ...localButtonStyle,
              borderRight: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
            onClick={() => {
              handleStepValue(-1)
            }}
          >
            <svg
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0"
                sx={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="23"
                height="22"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00195312 0H22.0019V22H0.00195312V0Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.4918 10.5061H11.4958H10.5081H6.48973C6.22034 10.5061 5.99585 10.7306 5.99585 11C5.99585 11.2694 6.22034 11.4939 6.48973 11.4939H10.4856L11.4736 11.5163H15.4693C15.7389 11.5163 15.9634 11.2918 15.9634 11.0225C15.9634 10.7531 15.7836 10.5061 15.4918 10.5061ZM11.0019 21.0122C5.47943 21.0122 1.01208 16.5224 1.01208 11.0224C1.01208 5.52245 5.50188 1.03265 11.0019 1.03265C16.5246 1.03265 20.9917 5.52245 20.9917 11.0224C20.9917 16.5224 16.5246 21.0122 11.0019 21.0122ZM11.002 0C4.94073 0 0.00195312 4.93878 0.00195312 11C0.00195312 17.0612 4.94073 22 11.002 22C17.0632 22 22.002 17.0612 22.002 11C22.002 4.93878 17.0632 0 11.002 0Z"
                  fill="#2B38BD"
                />
              </g>
            </svg>
          </Button>
          <Select
            arrow={<div as="svg"></div>} //TODO: remove arrow [requires theme-ui update]
            sx={
              !alt
                ? {
                    height: 50,
                    lineHeight: 1,
                    borderRadius: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    textAlign: 'center',
                    paddingLeft: '8px',
                  }
                : {
                    textAlign: 'center',
                    padding: 0,
                    border: 'none',
                    background: 'none',
                    lineHeight: 1,
                  }
            }
            name="quantity"
            onChange={onChange}
            ref={register}
            {...props}
          >
            {options.map(({ name, value }) => (
              <option key={value} value={value}>
                {name || value}
              </option>
            ))}
          </Select>
          <Button
            sx={{
              ...localButtonStyle,
              borderLeft: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            onClick={() => {
              handleStepValue(1)
            }}
          >
            <svg
              viewBox="0 0 23 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0"
                sx={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="23"
                height="22"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00195312 0H22.0019V22H0.00195312V0Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.4918 10.5061H11.4958V6.5102C11.4958 6.24082 11.2714 6.01633 11.002 6.01633C10.7326 6.01633 10.5081 6.24082 10.5081 6.5102V10.5061H6.48973C6.22034 10.5061 5.99585 10.7306 5.99585 11C5.99585 11.2694 6.22034 11.4939 6.48973 11.4939H10.4856V15.4898C10.4856 15.7592 10.7101 15.9837 10.9795 15.9837C11.2489 15.9837 11.4734 15.7592 11.4734 15.4898V11.5163H15.4691C15.7387 11.5163 15.9632 11.2918 15.9632 11.0224C15.9632 10.7531 15.7836 10.5061 15.4918 10.5061ZM11.002 21.0122C5.47955 21.0122 1.01221 16.5224 1.01221 11.0224C1.01221 5.52245 5.502 1.03265 11.002 1.03265C16.5245 1.03265 20.9918 5.52245 20.9918 11.0224C20.9918 16.5224 16.5245 21.0122 11.002 21.0122ZM11.002 0C4.94073 0 0.00195312 4.93878 0.00195312 11C0.00195312 17.0612 4.94073 22 11.002 22C17.0632 22 22.002 17.0612 22.002 11C22.002 4.93878 17.0632 0 11.002 0Z"
                  fill="#2B38BD"
                />
              </g>
            </svg>
          </Button>
        </Grid>
      )}
    </Flex>
  )
}

QuantitySelector.propTypes = {
  handleChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }),
  ),
}

export default QuantitySelector
