/** @jsx jsx */

import { useEffect, useState } from 'react'
import {
  Box,
  Text,
  Container,
  Flex,
  Heading,
  jsx,
  useColorMode,
} from 'theme-ui'
import {
  useCart,
  useTranslate,
  useUser,
} from '@chordcommerce/gatsby-theme-autonomy'
import OrderConfirmationReferralPrompt from '~/components/Order/Confirmation/ReferralPrompt'
import OrderConfirmationSummary from '~/components/Order/Confirmation/Summary'
import OrderConfirmationCart from '~/components/Order/Confirmation/Cart'
import OrderConfirmationDetails from '~/components/Order/Confirmation/Details'
import OrderConfirmationContact from '~/components/Order/Confirmation/Contact'
import YotpoOrderConfirmationScripts from '~/components/Order/Confirmation/YotpoOrderConfirmationScripts/index.js'
import { useStaticQuery, graphql, Link } from 'gatsby'
import PreOrderNote from '~/components/Mini-Cart/PreOrderNote'
import SubscriptionInfoNote from '~/components/Account/Subscription/SubscriptionInfo'
import Spinner from '~/components/Generic/Spinner'
import { useTikTokHelper } from '~/components/TikTokHelper'

const OrderConfirmationPage = ({ orderNumber, checkoutSessionId }) => {
  const { user, loadUser } = useUser()
  const { finalizeCheckout } = useCart()
  const [cart, setCart] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [colorMode, setColorMode] = useColorMode('CNF')
  const [hasSubscription, setHasSubscription] = useState(false)
  const [showShippingNote, setShowShippingNote] = useState(false)
  const { completePayment } = useTikTokHelper()

  useEffect(() => {
    setColorMode('CNF')
    finalizeCheckout({ orderNumber, checkoutSessionId })
      .then(cart => {
        setCart(cart)
        if (
          cart.lineItems?.filter(item => item.giftCards?.length === 0)?.length >
          0
        ) {
          setShowShippingNote(true)
        }
        setHasSubscription(
          cart?.lineItems &&
            cart.lineItems.some(item => item.subscriptionLineItems.length > 0),
        )
        const contentItems = []
        cart?.lineItems &&
          cart.lineItems.forEach(item =>
            contentItems.push({
              content_id: item.variant.sku,
              quantity: item.quantity,
              price: item.price,
            }),
          )
        return loadUser(cart.email)
      })
      .then(() => {
        setIsLoading(false)
      })
      .catch(error => {
        setError(error)
      })
  }, [finalizeCheckout, loadUser])

  useEffect(() => {
    if (cart && cart.state === 'complete') {
      ;(async () => {
        await completePayment(
          cart.id,
          cart.totalQuantity,
          cart.currency,
          cart.total,
        )
      })()
    }
  }, [cart])

  const { confirmationAssets } = useStaticQuery(graphql`
    query OrderConfimation {
      confirmationAssets: contentfulOrderConfirmation {
        donationLegalCopy {
          childMarkdownRemark {
            html
          }
        }
        delayedShippingNote
      }
    }
  `)

  const referralPurl = user.referralIdentifier && user.referralIdentifier.purl

  if (error) return <ConfirmationError error={error} />
  if (isLoading || !cart) return <ConfirmationLoading />

  return (
    <ConfirmationContainer cart={cart}>
      <YotpoOrderConfirmationScripts
        isLoading={isLoading}
        cart={cart}
        orderNumber={orderNumber}
      />
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
          }}
        >
          <Flex
            sx={{
              width: ['100%', null, '60%'],
              flexDirection: 'column',
              paddingRight: [null, null, '0.75rem'],
              textAlign: ['center', null, 'left'],
            }}
          >
            <OrderConfirmationDetails cart={cart} />

            {referralPurl && (
              <OrderConfirmationReferralPrompt purl={referralPurl} />
            )}

            <OrderConfirmationContact />
          </Flex>

          <Flex
            sx={{
              background: 'var(--theme-ui-colors-scndBg)',
              border: '3px solid var(--theme-ui-colors-secondary)',
              borderRadius: '8px',
              width: ['100%', null, '40%'],
              flexDirection: 'column',
              padding: '30px',
            }}
          >
            {hasSubscription && (
              <Box
                sx={{
                  borderRadius: '8px',
                  border: '2px #FFDD00 solid',
                  marginBottom: '20px',
                  color: 'inherit',
                  padding: '10px',
                  pt: '20px',
                  textAlign: 'center',
                }}
              >
                <Text>
                  To view and edit your subscriptions, checkout your{' '}
                  <Text
                    as={Link}
                    to={'/account/subscriptions'}
                    sx={{
                      textDecoration: 'underline',
                      ':hover': {
                        color: '#007300',
                      },
                    }}
                  >
                    Account
                  </Text>
                  <Box sx={{ pt: '10px' }}>or</Box>
                  <SubscriptionInfoNote
                    forwardSx={{
                      textAlign: 'center',
                      padding: 0,
                      border: 'none',
                      m: 0,
                    }}
                  />
                </Text>
              </Box>
            )}
            <Text
              sx={{
                width: '100%',
                borderBottom: '3px solid var(--theme-ui-colors-secondary)',
                fontFamily: 'Marby',
                textTransform: 'uppercase',
                paddingBottom: '30px',
                marginBottom: '30px',
              }}
            >
              {'Order Summary'}
            </Text>
            <OrderConfirmationCart cart={cart} />
            <OrderConfirmationSummary cart={cart} />
            {showShippingNote && (
              <PreOrderNote note={confirmationAssets.delayedShippingNote} />
            )}
            <Box
              dangerouslySetInnerHTML={{
                __html:
                  confirmationAssets.donationLegalCopy.childMarkdownRemark.html,
              }}
            ></Box>
          </Flex>
        </Flex>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationLoading = () => {
  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Flex
          sx={{
            textAlign: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            width: '100%',
            padding: ['1.25rem', '12rem 1.25rem'],
            marginRight: [null, '1rem'],
            marginBottom: ['1rem', null],
          }}
        >
          <Spinner size="150" sx={{ color: 'text' }} />
        </Flex>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationError = () => {
  const translate = useTranslate()

  return (
    <ConfirmationContainer loaded={false}>
      <Container>
        <Box
          sx={{
            width: '100%',
            backgroundColor: 'white',
            padding: '1rem',
            minHeight: '50vh',
          }}
        >
          <Heading variant="h3" color="errorDark">
            {translate('error.api.default')}
          </Heading>
        </Box>
      </Container>
    </ConfirmationContainer>
  )
}

const ConfirmationContainer = ({ children, cart = {}, loaded = true }) => {
  return (
    <Container>
      <Flex
        sx={{
          flexDirection: 'column',
          padding: ['24px 0', null, '48px 0'],
        }}
      >
        {loaded && (
          <Heading
            as="h2"
            variant="h2"
            sx={{
              marginBottom: ['24px', null, '64px'],
              textAlign: 'center',
            }}
          >
            {`Order Confirmation`}
          </Heading>
        )}
        {cart.number && (
          <Text
            as="h3"
            sx={{
              textAlign: 'center',
            }}
          >
            {`Order #:${cart.number}`}
          </Text>
        )}
        {children}
      </Flex>
    </Container>
  )
}

export default OrderConfirmationPage
