import { selectors } from '@chordcommerce/gatsby-theme-autonomy'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useProductMainImage = ({ slug, sku }) => {
  const { getAllProducts } = selectors
  const products = useSelector(getAllProducts)

  const [image, setImage] = useState(null)

  useEffect(() => {
    const product = products.find(p => p.slug === slug)

    if (!product) return
    const isKit = slug.includes('variety-pack')

    const image = isKit
      ? product.mainImage
      : product.variants.find(v => v.sku === sku).mainImage

    if (image) setImage(image)
  }, [products, sku, slug])

  return { image }
}

export default useProductMainImage
