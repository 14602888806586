import { graphql, useStaticQuery } from 'gatsby'
import { useEffect, useState } from 'react'

const usePromoProductImage = (sku, slug) => {
  const data = useStaticQuery(
    graphql`
      query specialItems {
        contentfulCollection(slug: { eq: "special-items" }) {
          products {
            slug
            variants {
              mainImage {
                title
                fluid(maxWidth: 500, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              sku
            }
          }
        }
      }
    `
  )
  const { products } = data.contentfulCollection

  const [image, setImage] = useState(null)

  useEffect(() => {
    const product = products.find(p => p.slug === slug)

    if (!product) return
    const isKit = slug.includes('variety-pack')

    const image = isKit
      ? product.mainImage
      : product.variants.find(v => v.sku === sku).mainImage

    if (image) setImage(image)
  }, [products, sku, slug])

  return image
}

export default usePromoProductImage
