import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import QuantitySelector from '~/components/Generic/QuantitySelector'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'

const CartQuantitySelector = ({ quantity, lineItemId, ...props }) => {
  const { modifyQuantity, removeFromCart } = useCart()
  const [isLoading, setIsLoading] = useState(false)

  const maxQuantity = Math.max(quantity, 20)
  const options = [{ name: '0 - Remove', value: 0 }].concat(
    [...Array(maxQuantity)].map((_, i) => ({
      name: (i + 1).toString(),
      value: i + 1
    }))
  )

  const updateQuantity = async quantity => {
    setIsLoading(true)
    const intQuantity = parseInt(quantity)
    try {
      if (intQuantity === 0) {
        await removeFromCart(lineItemId)
      } else {
        await modifyQuantity(lineItemId, intQuantity)
      }
      document.dispatchEvent(new CustomEvent('dk_cart_updated'))
    } catch (error) {}
  }

  useEffect(() => setIsLoading(false), [quantity, lineItemId])

  return (
    <QuantitySelector
      defaultValue={quantity}
      isLoading={isLoading}
      handleChange={updateQuantity}
      options={options}
      max={5}
      {...props}
    />
  )
}

CartQuantitySelector.propTypes = {
  lineItemId: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired
}

export default CartQuantitySelector
