import React from 'react'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import OrderConfirmationPage from '~/components/Order/Confirmation/Page'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const OrderConfirmation = ({ location }) => {
  const translate = useTranslate()

  const params = new URLSearchParams(location.search)

  const number = params.get('number') || ''
  const id = params.get('id') || ''

  return (
    <Layout>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage orderNumber={number} checkoutSessionId={id} />
    </Layout>
  )
}

export default OrderConfirmation
