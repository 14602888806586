/** @jsx jsx */
import PropTypes from 'prop-types'
import { Card, Heading, Box, Text, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Fragment } from 'react'

const OrderConfirmationDetails = ({ cart = {} }) => {
  const translate = useTranslate()

  const { email, payments, shipAddress } = cart

  // If only digital products exist, hide shipping note.
  const onlyDigitalProducts = cart?.lineItems.every(
    item => item.giftCards.length > 0,
  )

  return (
    <Card
      sx={{
        width: '100%',
        marginBottom: '1.5rem',
        padding: ['32px 18px', '2.5rem'],
      }}
    >
      <Heading as="p" variant="link" sx={{ marginBottom: '0.5rem' }}>
        {translate('confirmation.details.title')}
      </Heading>

      <Text as="p" sx={{ marginBottom: '0.5rem' }}>
        {translate('confirmation.details.text')} <strong>{email}</strong>.
      </Text>

      <Text
        as="p"
        variant="small"
        sx={{ marginBottom: ['2rem', null, '3rem'] }}
      >
        {translate('confirmation.details.subtitle')}
      </Text>

      {!onlyDigitalProducts && shipAddress && (
        <Fragment>
          <Heading as="p" variant="link" sx={{ marginBottom: '0.5rem' }}>
            {translate('confirmation.details.shipping')}
          </Heading>

          <Text as="p" sx={{ marginBottom: ['2rem', null, '3rem'] }}>
            {shipAddress.fullName}
            <br />
            {shipAddress.address1},{' '}
            {shipAddress.address2 && `${shipAddress.address2}, `}
            {shipAddress.city}, {shipAddress.stateText}, {shipAddress.zipcode}
          </Text>
        </Fragment>
      )}

      {payments && payments.length > 0 && (
        <Box>
          <Heading as="p" variant="link" sx={{ marginBottom: '0.5rem' }}>
            {translate('confirmation.details.payment')}
          </Heading>

          {payments
            .filter(payment => payment.state === 'completed')
            .map(payment => {
              if (payment.sourceType === 'Spree::StoreCredit')
                return (
                  <Text
                    as="p"
                    sx={{ textTransform: 'capitalize' }}
                    key={payment.id}
                  >
                    Store Credit - {payment.displayAmount}
                  </Text>
                )
              if (
                payment.sourceType === 'Spree::CreditCard' &&
                payment.amount > 0
              ) {
                return (
                  <Text
                    as="p"
                    sx={{ textTransform: 'capitalize' }}
                    key={payment.id}
                  >
                    {payment.source.ccType} (**** {payment.source.lastDigits}) -{' '}
                    {payment.displayAmount}
                  </Text>
                )
              }
              return null
            })}
        </Box>
      )}
    </Card>
  )
}

OrderConfirmationDetails.propTypes = {
  cart: PropTypes.shape({
    email: PropTypes.string,
    number: PropTypes.string,
    payments: PropTypes.array,
    shipAddress: PropTypes.shape({}),
  }),
}

export default OrderConfirmationDetails
